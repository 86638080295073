<template>
  <div class="claim-benefits-container">
    <span>Rewards Program Benefits</span>
    <div class="claim-benefits-container-content">
      <b-icon icon="shield-check" style="color: #00b9cc" font-scale="2"></b-icon>
      <p class="m-1">Redeem Free Insurance</p>
      <b-form-checkbox class="ml-1" v-model="value" />
      <i
        v-b-popover.hover="this.$t('carriers.modalInfo.rewardsProgramBenefits')"
        class="ion ion-information-circle-outline ml-2 mt-1"
      >
      </i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClaimBenefitsContainer",
  data() {
    return {
      value: false,
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-benefits-container {
  span {
    font-weight: bold;
  }
  &-content {
    display: flex;
  }
}
</style>
