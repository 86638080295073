/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-len
import dayjs from "dayjs";
import formatterCarrierName from "@/Extend/FormatCarrierName";
import QuoteAcceptanceModal from "../views/QuoteBook/CarrierSelection/carriersList/components/QuoteAcceptanceModal.vue";
import FormatEasyPostPrices from "./FormatEasyPostPrices";

export default {
  props: {
    carrier: Object,
    carrierImage: Object,
    aditionalInsurance: Number,
    loadingInsurance: Boolean,
    originalCarrierArray: Array,
    isPickup: Boolean
  },
  mixins: [formatterCarrierName, FormatEasyPostPrices],
  components: {
    QuoteAcceptanceModal,
  },
  data() {
    return {
      showModal: false,
      insurance: false,
      canDispatch: null,
      showPickupPrice: false,
      ratePickupMessage: "You can proceed  in the next step with the rate including pickup, please click on book."
    };
  },
  created() {
    this.canDispatch = this.$store.getters["login/getTokenInfo"].can_dispatch;
  },
  computed: {
    setTransitTime() {
      if (this.carrier.transitTime === 0 || this.carrier.carrier === "305 EXPRESS USA (LTL)" || this.carrier.carrier === "305 EXPRESS USA (FTL)") {
        return "Same day delivery";
      }
      return `${this.carrier.transitTime} Business days`;
    },
    checkIfCarrierIsGuaranteed() {
      const serviceClass = this.carrier.serviceClass.toLowerCase();
      const serviceClassList = ["guaranteed", "expedited", "time critical", "accelerated"];
      return serviceClassList.some((item) => serviceClass.includes(item));
    },
  },
  methods: {
    addCarrierModeToName(carrier) {
      const carrierName = carrier.toLowerCase();
      if (carrierName.includes("vltl")) {
        return " (VLTL)";
      }
      if (carrierName.includes("ltl")) {
        return " (LTL)";
      }
      if (carrierName.includes("ftl")) {
        return " (FTL)";
      }
      return "";
    },
    validateIfTheCarrierCanBuyPickup(carrier) {
      if (carrier.source === "easy-post" && (carrier.scac === "UPSN" || carrier.scac === "FDEG" || carrier.scac === "USPS")) {
        this.showPickupPrice = true;
        return;
      }
      this.showPickupPrice = false;
    },
    selectedCarrier(insurance, carrier, pickup) {
      if (this.canDispatch || this.canDispatch === null) {
        this.$emit("handleIsPickup", pickup);
        if (carrier.is_expired) {
          this.swal({
            title: "This carrier is no longer available.",
            icon: "warning",
            dangerMode: false,
          });
        } else {
          const { description, insuranceAlert } = carrier;
          this.validateIfTheCarrierCanBuyPickup(carrier);
          this.insurance = insurance;
          this.showModal = true;
          this.$store.commit("transportSelected/modifyTransportModalInformation", {
            key: "selectedTransport",
            value: carrier,
          });
          this.$store.commit("transportSelected/modifyTransportModalInformation", {
            key: "portalNotes",
            value: description,
          });
          this.$store.commit("transportSelected/modifyTransportModalInformation", {
            key: "insuranceAlert",
            value: insuranceAlert,
          });
        }
      }
    },
    optionSelected({ enableLoyaltyInsurance, buyPickup, insurance }) {
      // eslint-disable-next-line max-len
      const { selectedTransport } =
        this.$store.getters["transportSelected/getTransportModalInformation"];
      const { _id } = selectedTransport;
      const { name } = selectedTransport;
      const params = {
        isInsurance: insurance,
        carrierId: _id,
        carrierName: name,
        loyaltyInsurance: enableLoyaltyInsurance,
        pickup: buyPickup
      };
      if (buyPickup) {
        const carrierWithPickup = this.originalCarrierArray.filter(
          (item) => item.carrier === selectedTransport.carrier && item.accesorials.some((accesorial) => accesorial.Name === "parcel pick up fee")
        );
        if (carrierWithPickup.length) {
          params.carrierId = carrierWithPickup[0]._id;
          params.carrierName = carrierWithPickup[0].carrier;
        }
      }
      this.$emit("optionSelected", { ...params, changeView: true });
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
    dateExpired(expirationDate) {
      const today = new Date();
      return dayjs(today).isAfter(expirationDate);
    },
    checkIfCarrierHasExpired(carrier) {
      if ("is_expired" in carrier) {
        return carrier.is_expired;
      }
      return this.dateExpired();
    },
  },
};
