<template>
  <div class="portal-notes-container">
    <p>{{ $t("carriers.modalInfo.portalNotes") }}</p>
    {{ note }}
  </div>
</template>

<script>
export default {
  name: "PortalNotes",
  props: {
    note: String,
  },
};
</script>

<style lang="scss" scoped>
.portal-notes-container {
  p {
    font-weight: bold;
    margin-bottom: 10px;
  }
  @include font-standart-text;
  margin-bottom: 0px;
}
</style>
