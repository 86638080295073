<template>
  <div class="alert-insurance-recommend">
    <div class="icon-container">
      <i class="ion ion-warning-outline"></i>
    </div>
    <div class="alert-insurance-recommend-container">
      <div class="alert-insurance-recommend-container__title">
        Ensure peace of mind: Consider Adding Insurance
      </div>
      <div class="alert-insurance-recommend-container__text">
        The carrier you are considering has low liability, we strongly recommend you to
        include insurance to safeguard your cargo
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertInsuranceRecommend",
};
</script>

<style lang="scss" scoped>
.alert-insurance-recommend {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 6px;
  background: #d1d7de;
  border-radius: 13px;
  margin-top: 13px;
  &-container {
    &__title {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__text {
      font-size: 0.95rem;
    }
  }
}
.icon-container i {
  color: #ED776F;
  font-size: 23px;
}
</style>
