<template>
  <div class="quote-acceptance-buttons">
    <div class="pickup-buttons-container" v-if="showPickupButtonsWithoutInsurance">
      <button
        class="pickup-buttons-container__button pickup-buttons-container__button--dark"
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: false, insurance: isInsurance })"
      >
        <span>DROP OFF <br />DAILY PICKUP</span>
        <span>USD {{ formatEasyPostPriceWithoutPickup(carrier).toFixed(2) | priceToUsd }}</span>
      </button>
      <button
        class="pickup-buttons-container__button pickup-buttons-container__button--sky"
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: true, insurance: isInsurance })"
      >
        <span>BUY PICKUP</span>
        <span>USD {{ formatEasyPostPriceWithPickup(carrier.quoteContractId).toFixed(2) | priceToUsd }}</span>
      </button>
    </div>
    <div class="pickup-buttons-container" v-else-if="showPickupButtonsWithInsurance">
      <button
        class="pickup-buttons-container__button pickup-buttons-container__button--dark"
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: false, insurance: isInsurance })"
      >
        <span>DROP OFF <br />DAILY PICKUP</span>
        <span
          >USD
          {{
            (formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance).toFixed(2) | priceToUsd
          }}</span
        >
      </button>
      <button
        class="pickup-buttons-container__button pickup-buttons-container__button--sky"
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: true, insurance: isInsurance })"
      >
        <span>BUY PICKUP</span>
        <span
          >USD
          {{
            (formatEasyPostPriceWithPickup(carrier.quoteContractId) + aditionalInsurance).toFixed(2) | priceToUsd
          }}</span
        >
      </button>
    </div>
    <div class="recommend-buttons-container" v-else-if="showInsuranceRecommendButtons">
      <button
        class="recommend-buttons-container__button recommend-buttons-container__button--dark"
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: true, insurance: isInsurance })"
      >
        <span>BOOK</span>
        <span>USD {{ formatEasyPostPriceWithoutPickup(carrier).toFixed(2) | priceToUsd }}</span>
      </button>
      <button
        class="recommend-buttons-container__button recommend-buttons-container__button--green"
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: true, insurance: isInsurance })"
      >
        <span>BOOK WITH <br />INSURANCE</span>
        <span
          >USD
          {{
            (formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance).toFixed(2) | priceToUsd
          }}</span
        >
      </button>
    </div>
    <div class="quote-continue-button" v-else>
      <button
        :class="!confirmTermsAndConditions ? 'disabled-button' : ''"
        :disabled="!confirmTermsAndConditions"
        @click="$emit('continueToTendering', { buyPickup: true, insurance: isInsurance })"
      >
        {{ $t("carriers.modalInfo.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
import FormatEasyPostPrices from "../../../../../Extend/FormatEasyPostPrices";

export default {
  name: "QuoteAcceptanceButtons",
  mixins: [FormatEasyPostPrices],
  props: {
    carrier: Object,
    lineItems: Array,
    aditionalInsurance: Number,
    isDropOffPickup: Boolean,
    isInsurance: Boolean,
    isInsuranceRecommend: Boolean,
    confirmTermsAndConditions: Boolean,
    originalCarrierArray: Array
  },
  computed: {
    showPickupButtonsWithoutInsurance() {
      return this.isDropOffPickup && !this.isInsurance;
    },
    showPickupButtonsWithInsurance() {
      if (this.isDropOffPickup && this.isInsurance) {
        return true;
      }
      return false;
    },
    showInsuranceRecommendButtons() {
      return !this.isInsurance && !this.isDropOffPickup && this.isInsuranceRecommend;
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-continue-button {
  text-align: center;
  margin-top: 20px;
  button {
    @include primary-button;
    padding: 7px;
    width: 150px;
  }
}

.pickup-buttons-container,
.recommend-buttons-container {
  @include button-book-carriers();
}

.disabled-button {
  background-color: $color-disabled !important;
}
</style>
