<template>
  <div class="info-about-insurance">
    <span>{{ $t("carriers.modalInfo.insurance") }}</span>
    <p v-if="transportModalInfo.insurance">
      Insured, cargo value: {{ transportModalInfo.insurance }}
    </p>
    <p v-else>Up to Cargo Value $25000</p>
  </div>
</template>

<script>
export default {
  name: "InfoAboutInsurance",
  props: {
    transportModalInfo: Object,
  },
};
</script>

<style lang="scss" scoped>
.info-about-insurance {
  span {
    font-weight: bold;
  }
  p {
    margin: 0px;
  }
}
</style>
